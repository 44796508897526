import { push }                                 from 'connected-react-router';
import {
  dmpCommandFailureContextualizedType, dmpCommandSuccessContextualizedType, setModalError, setMssConfiguration,
}                                                                                             from 'dmpconnectjsapp-base/actions';
import commands, {
  mssSubTypes
}                                                                                             from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections, mssActionConstants, mssLoginTypes }                                     from 'dmpconnectjsapp-base/constants';
import {
  getApiType, getMssAccounts, getMssEmail, getMssEmailFromUserCpx, getMssReplyToFromUserCpx, getMssSenderWordingFromUserCpx
  
}                                                            from 'dmpconnectjsapp-base/helpers/accessors';
import { hasError, isLoading, isReady }                                                       from 'dmpconnectjsapp-base/helpers/common';
import {
  authenticationTypes, getAccessRightsProps
}                                                                                             from 'dmpconnectjsapp-base/rules/accessRights';
import JSZip                                                                                  from 'jszip';
import moment                                                                                 from 'moment';
import { toast }                                                                              from 'react-toastify';
import { all, call, put, select, take, }                                                      from 'redux-saga/effects';
import { ValidationError }                                                                    from 'yup';
import env                                                                                    from '../../envVariables';
import {
  addMssAccount, getAction, getDirectAuthenticationDMPStatus, markMessageAsRead, setMssAccountActive, setMssDownloadedAttachment, setMssPatientInfos, setMSSSearchedMessagesIds,
  setPersistedAppConfiguration, setPersistedMssFolders, setPersistedMssSyncMessages,
}                                                                                             from '../actions';
import { API_TYPES, dmpconnectConfigurationActionConstants, mssActions }                      from '../constants';
import { insTypes }                                                                           from '../constants/dmpConstants';
import { createErrorDetails, createModalError }                                               from '../errors';
import { errorActions }                                                                       from '../errors/errorActions';
import { errorTypes, softwareErrors }                                                         from '../errors/errorConfiguration';
import {
  getDirectAuthenticationStatus, getSelectedPatientInfos
}                                                                                             from '../helpers/directAuthenticationDMPStatus';
import { isImapSynchronous, isMssPatientEmail, mssFolderTypes, mssOperatorsConfigValidator, } from '../helpers/mss';
import { getXmlElement }                                                                      from '../helpers/xml';
import { b64DecodeUnicode, generateUniqueId, imapUtf7Decode }                                                   from '../utils/dataUtils';
import { postMessageToIframeParent }                                                          from '../utils/iframe';

const getConfigMssPatientInfosApiEnable = ({ dmpconnectMSSConfiguration }) => dmpconnectMSSConfiguration.mssPatientInfosRequestsEnabled;
const getConfigMssOperator              = ({ dmpconnectMSSConfiguration }) => dmpconnectMSSConfiguration.mssOperator;
const getConfigMssApiType               = ({ dmpconnectMSSConfiguration }) => dmpconnectMSSConfiguration.mssApiType;
const getConfigMssLoginType             = ({ dmpconnectMSSConfiguration }) => dmpconnectMSSConfiguration.mssLoginType;
const getStateMssApiType                = ({ mssMessages: { mssApiType } }) => mssApiType;

export const getMssEmailAttachments = (state) => {
  const {
          dmpconnectApplication: {
            mssDownloadedAttachment,
          },
        } = state;
  return mssDownloadedAttachment;
};

export const initMssState = function* () {
  const userPrefMssEmail = yield select(getMssEmailFromUserCpx);
  const senderWording    = yield select(getMssSenderWordingFromUserCpx);
  const replyTo          = yield select(getMssReplyToFromUserCpx);
  
  const mssAccounts      = yield select(getMssAccounts);
  const configMssApiType = yield select(getConfigMssApiType);
  const stateMssApiType  = yield select(getStateMssApiType);
  const mssDefaultConfig = yield select(({ dmpconnectMSSConfiguration }) => dmpconnectMSSConfiguration);
  const { accessRights } = yield select(getAccessRightsProps);
  
  if (userPrefMssEmail && mssAccounts.length === 0) {
    const account = {
      id              : generateUniqueId(),
      mssEmail        : userPrefMssEmail,
      mssSenderWording: senderWording,
      mssReplyTo      : replyTo,
      mssLoginType    : mssDefaultConfig.mssLoginType,
      mssApiType      : mssDefaultConfig.mssApiType,
      mssImapServer   : mssDefaultConfig.mssImapServer,
      mssImapPort     : mssDefaultConfig.mssImapPort,
      mssSmtpServer   : mssDefaultConfig.mssSmtpServer,
      mssSmtpPort     : mssDefaultConfig.mssSmtpPort,
      mssImapLogin    : mssDefaultConfig.mssImapLogin,
      mssImapPasswd   : mssDefaultConfig.mssImapPasswd,
      mssImapSaslLogin: mssDefaultConfig.mssImapSaslLogin,
      mssSmtpLogin    : mssDefaultConfig.mssSmtpLogin,
      mssSmtpPasswd   : mssDefaultConfig.mssSmtpPasswd,
      mssSmtpSaslLogin: mssDefaultConfig.mssSmtpSaslLogin,
      mssOperator     : mssDefaultConfig.mssOperator,
    };
    
    yield put(addMssAccount(accessRights.psId, account));
    yield put(setMssAccountActive(accessRights.psId, account));
  }
  
  const mssActiveEmail = yield select(getMssEmail);
  if (!mssActiveEmail && mssAccounts.length > 0) {
    const [activeAccount] = mssAccounts;
    yield put(setMssAccountActive(accessRights.psId, activeAccount));
  }
  
  if (stateMssApiType !== configMssApiType) {
    yield put({ type: mssActionConstants.EMPTY_ALL_MSS_MESSAGES });
    yield put({ type: mssActionConstants.SET_MSS_MESSAGES_API_TYPE, apiType: configMssApiType });
  }
};

export const handleGetFoldersFromMssResult = function* (action) {
  const { data, context: { mssApiType, save = true } } = action;
  if (save === true) {
    const mssEmail = yield select(getMssEmail);
    let foldersArray;
    let foldersMapper;
    if (mssApiType === mssSubTypes.WEB) {
      const { s_answerBodyInBase64 } = data;
      const body                     = b64DecodeUnicode(s_answerBodyInBase64);
      const parser                   = new DOMParser();
      const xmlDoc                   = parser.parseFromString(body, 'text/xml');
      
      const foldersElems = getXmlElement(xmlDoc, 'ns2:listFoldersResponse', 0).childNodes;
      foldersArray       = Array.from(foldersElems);
      foldersMapper      = folder => ({
        name: getXmlElement(folder, 'folderName', 0).textContent,
        id  : Number.parseInt(getXmlElement(folder, 'folderId', 0).textContent, 10),
        // isSynced: false,
        folders: folder.getElementsByTagName('folders').length > 0
                 ? Array.from(folder.childNodes).filter(f => f.tagName === 'folders').map(foldersMapper)
                 : [],
      });
    } else if (mssApiType === mssSubTypes.IMAP) {
      const { SubFolder }       = data;
      let foldersToProcess      = SubFolder;
      const foldersWithChildren = foldersToProcess.filter(f => f.Flags.includes('\\HasChildren') && f.s_name.indexOf('/') === -1);
      foldersToProcess          = foldersToProcess.filter(f => !foldersWithChildren.some(c => c.s_name === f.s_name));
      
      const getChildFolders = (prefix, level) => {
        const children   = foldersToProcess.filter(f => f.s_name.startsWith(`${prefix}/`) && f.s_name.split('/').length - 1 === level);
        foldersToProcess = foldersToProcess.filter(f => !children.some(c => c.s_name === f.s_name));
        return children.map(folder => ({
          name: imapUtf7Decode(folder.s_name.replace(`${prefix}/`, '')),
          id  : folder.s_name,
          // isSynced: false,
          folders: getChildFolders(folder.s_name, level + 1),
        }));
      };
      
      foldersArray = foldersWithChildren.map(folder => ({
        name: imapUtf7Decode(folder.s_name),
        id  : folder.s_name,
        // isSynced: false,
        folders: getChildFolders(folder.s_name, 1),
      }));
      foldersArray = [
        ...foldersArray,
        ...foldersToProcess.map(folder => ({
          name: imapUtf7Decode(folder.s_name),
          id  : folder.s_name,
          // isSynced: false,
          folders: [],
        })),
      ];
      
      foldersMapper = folder => folder;
    }
    
    const hiddenFolders = [
      'Briefcase',
      'Calendar',
      'Chats',
      'Contacts',
      'Drafts',
      'Emailed Contacts',
      'Tasks',
    ];
    
    const translatedFolders = {
      [mssFolderTypes.INBOX]: 'Boîte de réception',
      [mssFolderTypes.SENT] : 'Envoyés',
      [mssFolderTypes.JUNK] : 'Spams',
      [mssFolderTypes.TRASH]: 'Corbeille',
    };
    
    const folders = foldersArray.map(foldersMapper).filter(f => (hiddenFolders.indexOf(f.name) === -1)).map((f) => {
      if (translatedFolders[f.name.toLowerCase()]) {
        return {
          ...f,
          name   : translatedFolders[f.name.toLowerCase()],
          type   : f.name.toLowerCase(),
          special: true,
        };
      }
      return f;
    });
    yield put(setPersistedMssFolders(mssEmail, folders));
  }
};

const messagesGetter = function (messages) { // convert array of xml elements 'messages' to object
  const addressGetter = (addresses, type) => {
    const formatedAddresses = [];
    for (let i = 0; i < addresses.length; i += 1) {
      if (addresses[i].getElementsByTagName('type').length > 0
          && addresses[i].getElementsByTagName('type')[0].textContent === type) {
        const email = addresses[i].getElementsByTagName('email')[0].textContent;
        let name    = email;
        if (addresses[i].getElementsByTagName('name').length > 0) {
          name = addresses[i].getElementsByTagName('name')[0].textContent;
        }
        formatedAddresses.push({
          email,
          name,
        });
      }
    }
    return formatedAddresses;
  };
  
  const flagGetter = (flags, type) => {
    for (let i = 0; i < flags.length; i += 1) {
      if (flags[i].textContent === type) {
        return true;
      }
    }
    return false;
  };
  
  const attachmentMapper = attachment => (
    {
      contentType: getXmlElement(attachment, 'contentType', 0).textContent,
      fileName   : getXmlElement(attachment, 'fileName', 0).textContent,
      part       : getXmlElement(attachment, 'part', 0).textContent,
      size       : Number.parseInt(getXmlElement(attachment, 'size', 0).textContent, 10),
    }
  );
  
  const messagesMapper = message => ({
    from       : addressGetter(Array.from(message.childNodes).filter(x => x.tagName === 'addresses'), 'FROM'),
    to         : addressGetter(Array.from(message.childNodes).filter(x => x.tagName === 'addresses'), 'TO'),
    cc         : addressGetter(Array.from(message.childNodes).filter(x => x.tagName === 'addresses'), 'CC'),
    bcc        : addressGetter(Array.from(message.childNodes).filter(x => x.tagName === 'addresses'), 'BCC'),
    date       : moment.utc(getXmlElement(message, 'date', 0).textContent, 'DD-MM-YYYY HH:mm:ss').toDate().getTime(), // dd/mm/yyyy hh:mm:ss
    subject    : getXmlElement(message, 'subject', 0).textContent,
    fragment   : getXmlElement(message, 'fragment', 0).textContent,
    body       : getXmlElement(message, 'body', 0).textContent || 'EFFICIENCE_NO_BODY',
    folderId   : Number.parseInt(getXmlElement(message, 'folderId', 0).textContent, 10),
    flags      : {
      unread  : flagGetter(Array.from(message.childNodes).filter(x => x.tagName === 'flags'), 'UNREAD'),
      urgent  : flagGetter(Array.from(message.childNodes).filter(x => x.tagName === 'flags'), 'URGENT'),
      priority: flagGetter(Array.from(message.childNodes).filter(x => x.tagName === 'flags'), 'PRIORITY'),
    },
    attachments: Array.from(message.childNodes).filter(x => x.tagName === 'attachments').map(attachmentMapper),
    messageId  : Number.parseInt(getXmlElement(message, 'messageId', 0).textContent, 10),
  });
  return messages.map(messagesMapper);
};

const extractNameFromEmail = (email) => {
  const [start] = email.split('@');
  return start.split('.').join(' ');
};
const imapAddressGetter    = (str) => {
  if (!str) {
    return undefined;
  }
  let email = '';
  let name  = '';
  
  const reEmail                                  = /<(?<email>.*)>/;
  const { groups: { email: matchedEmail } = {} } = str.match(reEmail) || {};
  
  if (matchedEmail) {
    email                                        = matchedEmail;
    const reName                                 = /(?<name>.*)\s*</;
    const { groups: { name: matchedName } = {} } = str.match(reName) || {};
    name                                         = matchedName && matchedName.length > 0 ? matchedName : extractNameFromEmail(matchedEmail);
  } else {
    email = str;
    name  = extractNameFromEmail(str);
  }
  
  // if (email === 'jeanphilippe.oeil7@medecin.formation.mssante.fr') {
  //   email = '146026322000196@patient.mssante.fr';
  // }
  
  return { name, email };
};

function parseWebSearchMessagesResult(action) {
  const { command: { s_requestBodyInBase64 }, data: { s_answerBodyInBase64 } } = action;
  const request                                                                = b64DecodeUnicode(s_requestBodyInBase64);
  const body                                                                   = b64DecodeUnicode(s_answerBodyInBase64);
  const parser                                                                 = new DOMParser();
  const xmlRequest                                                             = parser.parseFromString(request, 'text/xml');
  const xmlDoc                                                                 = parser.parseFromString(body, 'text/xml');
  
  const folderId = Number.parseInt(getXmlElement(xmlRequest, 'folderId', 0).textContent, 10);
  
  const messagesElems = getXmlElement(xmlDoc, 'ns2:searchMessagesResponse', 0).childNodes;
  const messagesArray = Array.from(messagesElems);
  const messages      = messagesGetter(messagesArray);
  return { folderId, messages };
}

function parseImapSearchMessagesResult(action) {
  const { data: { Messages }, command: { s_folderName } } = action;
  
  const localMoment = moment;
  localMoment.locale('en');
  const messages = Messages.map((message) => {
    const {
            s_from,
            s_to,
            s_cc,
            s_subject,
            s_date,
            s_uid,
            Flags,
            Structure: {
              s_mimeSubType,
              SubStructrure,
              SubStructure,
            } = {},
          } = message;
    
    const subStructure = SubStructure || SubStructrure || [];
    
    return {
      from: s_from.split(';').map(addr => imapAddressGetter(addr)),
      to  : s_to.split(';').map(addr => imapAddressGetter(addr)),
      cc  : s_cc ? s_cc.split(';').map(addr => imapAddressGetter(addr)) : [],
      // bcc: addressGetter(message.s_bcc),
      date         : localMoment(s_date, 'DD-MMM-YYYY HH:mm:ss ZZ').toDate().getTime(),
      subject      : s_subject,
      messageId    : s_uid,
      hasAttachment: s_mimeSubType === 'mixed' && subStructure.length > 1,
      attachments  : [],
      fragment     : undefined,
      body         : undefined,
      folderId     : s_folderName,
      flags        : {
        unread : !Flags.some(flag => flag.indexOf('Seen') !== -1),
        urgent : Flags.some(flag => flag.indexOf('Flagged') !== -1),
        deleted: Flags.some(flag => flag.indexOf('Deleted') !== -1),
      },
    };
  });
  localMoment.locale('fr');
  return { s_folderName, messages: messages.filter(m => !m.flags.deleted) };
}

export const handleSearchMessagesFromMssResult = function* (action) {
  const { context: { mssApiType } } = action;
  const mssEmail                    = yield select(getMssEmail);
  
  // let messagesLDAP = [];
  
  if (mssApiType === mssSubTypes.WEB) {
    const { folderId, messages } = parseWebSearchMessagesResult(action);
    yield put(setPersistedMssSyncMessages(mssEmail, {
      deletedMessageIds: [], modifiedMessages: messages, folderId,
    }));
  }
  
  if (mssApiType === mssSubTypes.IMAP) {
    const { s_folderName, messages } = parseImapSearchMessagesResult(action);
    yield put(setPersistedMssSyncMessages(mssEmail, { allMessages: messages, token: moment().unix(), folderId: s_folderName }));
  }
};

export const handleFullSearchMessagesFromMssResult = function* (action) {
  const { context: { mssApiType, hasAttachments } } = action;
  const mssEmail                                    = yield select(getMssEmail);
  let messages                                      = [];
  
  if (mssApiType === mssSubTypes.WEB) {
    const { folderId, messages: messagesResult } = parseWebSearchMessagesResult(action);
    yield put(setPersistedMssSyncMessages(mssEmail, {
      deletedMessageIds: [],
      modifiedMessages : messagesResult,
      folderId,
    }));
    messages = messagesResult;
  }
  
  if (mssApiType === mssSubTypes.IMAP) {
    const { s_folderName, messages: messagesResult } = parseImapSearchMessagesResult(action);
    yield put(setPersistedMssSyncMessages(mssEmail, {
      allMessages: messagesResult,
      token      : moment().unix(),
      folderId   : s_folderName,
    }));
    messages = messagesResult;
  }
  
  yield put(setMSSSearchedMessagesIds(mssEmail, { messageIds: messages.map(m => m.messageId), hasAttachments }));
};

export const handleSyncMessagesFromMssResult = function* (action) {
  const { data: { s_answerBodyInBase64 } } = action;
  const mssEmail                           = yield select(getMssEmail);
  const body                               = b64DecodeUnicode(s_answerBodyInBase64);
  const parser                             = new DOMParser();
  const xmlDoc                             = parser.parseFromString(body, 'text/xml');
  
  const syncedElems       = Array.from(getXmlElement(xmlDoc, 'ns2:syncMessagesResponse', 0).childNodes);
  const messagesArray     = syncedElems.filter(el => el.tagName === 'modifiedMessages');
  const modifiedMessages  = messagesGetter(messagesArray);
  const deletedMessageIds = syncedElems.filter(el => el.tagName === 'deletedMessageIds').map(el => Number.parseInt(el.textContent, 10));
  const token             = syncedElems.filter(el => el.tagName === 'token')[0].textContent;
  yield put(setPersistedMssSyncMessages(mssEmail, {
    deletedMessageIds, modifiedMessages, token, folderId: null,
  }));
};

export const handleUpdateMessagesFromMssResult = function* (action) {
  const {
          context: {
            messageIds,
            operation,
            email,
          } = {},
        } = action;
  
  if (messageIds) {
    yield put(markMessageAsRead(email, messageIds, operation === 'READ'));
  }
};

export const handleMoveMessagesFromMssResult = function* (action) {
  const {
          context: {
            destinationFolderId,
            email,
            synchronous,
            mssApiType,
            refreshDestination,
          } = {},
        } = action;
  
  if (refreshDestination) {
    const mssSyncToken = yield select((state) => {
      const {
              mssMessages: {
                [email]: {
                  MssSyncToken: mssToken = '',
                } = {},
              } = {},
            } = state;
      return mssToken;
    });
    
    yield put(getAction(
      commands.getSyncMessagesMSS,
      apiSections.MSS_SYNC_MESSAGES,
      { email, folderId: destinationFolderId, token: mssSyncToken },
      {
        synchronous,
        subConfig   : mssApiType,
        contextExtra: { mssApiType, forceAction: errorActions.NONE },
      },
    ));
  }
};

export const handleDownloadAttachmentFromMssResult = function* (action) {
  const { command: { s_requestBodyInBase64 }, data: { s_answerBodyInBase64 }, context: { email } } = action;
  const reqBody                                                                                    = b64DecodeUnicode(s_requestBodyInBase64);
  const ansBody                                                                                    = b64DecodeUnicode(s_answerBodyInBase64);
  const parser                                                                                     = new DOMParser();
  const reqXmlDoc                                                                                  = parser.parseFromString(reqBody, 'text/xml');
  const ansXmlDoc                                                                                  = parser.parseFromString(ansBody, 'text/xml');
  
  const messageId      = Number.parseInt(getXmlElement(reqXmlDoc, 'messageId', 0).textContent, 10);
  const part           = getXmlElement(reqXmlDoc, 'part', 0).textContent;
  const attachementB64 = getXmlElement(ansXmlDoc, 'ns2:downloadAttachmentResponse', 0).childNodes[0].textContent;
  
  yield put(setMssDownloadedAttachment({
    email, messageId, part, content: attachementB64,
  }));
};

const generateOtherAttachments = attachment => (
  '<attachments>'
  + `<contentType>${attachment.contentType}</contentType>`
  + `<fileName>${attachment.documentTitle}</fileName>`
  + `<file>${attachment.fileContentInBase64}</file>`
  + '</attachments>'
);

export const handleSendMssWebEmail = function* (action) {
  const { emailContent, esUser } = action;
  const emailContentAttachments  = emailContent.attachments || emailContent.Documents || [];
  
  if (emailContent.otherAttachments && emailContent.otherAttachments.length > 0) {
    // const otherAttachmentsString = emailContent.otherAttachments
    //   .map(attachment => (
    //     '<attachments>'
    //     + `<contentType>${documentFormatTypeMimes[attachment.documentFormat] || 'text/plain'}</contentType>`
    //     + `<fileName>${attachment.documentTitle}</fileName>`
    //     + `<file>${attachment.fileContentInBase64}</file>`
    //     + '</attachments>'))
    //   .join('');
    const otherAttachmentsArray  = yield all(emailContent.otherAttachments.map(attachment => call(generateOtherAttachments, attachment)));
    const otherAttachmentsString = otherAttachmentsArray.join('');
    
    let generatedAttachments = '';
    
    if (emailContentAttachments.length > 0) {
      yield put(getAction(
        commands.generateMssAttachments,
        apiSections.MSS_GENERATE_ATTACHMENTS,
        {
          attachments                 : emailContentAttachments,
          ins                         : emailContent.s_ins,
          healthcareSetting           : emailContent.s_healthcareSetting,
          esUser,
          Identity                    : emailContent.Identity,
          insIsNotQualified           : Number(emailContent.i_insIsNotQualified || 0),
          AdditionalPatientIdentifiers: emailContent.AdditionalPatientIdentifiers,
          
          disableIheXdmPdfTitlePage      : emailContent.disableIheXdmPdfTitlePage === true ? 1 : 0,
          disableIheXdmPdfDataMatrixBlock: emailContent.disableIheXdmPdfDataMatrixBlock === true ? 1 : 0,
        },
        {
          synchronous : true,
          contextExtra: { priority: true, mssEmailConfig: emailContent.sender },
          silentError : true,
        },
      ));
      
      const attachments = yield take([
        dmpCommandSuccessContextualizedType(apiSections.MSS_GENERATE_ATTACHMENTS),
        dmpCommandFailureContextualizedType(apiSections.MSS_GENERATE_ATTACHMENTS),
      ]);
      
      if (attachments.type === dmpCommandSuccessContextualizedType(apiSections.MSS_GENERATE_ATTACHMENTS)) {
        const { data: { s_attachmentsBufferInBase64 } } = attachments;
        generatedAttachments                            = b64DecodeUnicode(s_attachmentsBufferInBase64);
      } else {
        yield put({
          ...attachments,
          type: dmpCommandFailureContextualizedType(apiSections.MSS_SEND_SMTP_EMAIL),
        });
        return;
      }
    }
    
    yield put(getAction(
      commands.sendMssWebEmailSoap,
      apiSections.MSS_SEND_SMTP_EMAIL,
      { ...emailContent, attachments: `${generatedAttachments}${otherAttachmentsString}` },
      {
        synchronous : true,
        contextExtra: { priority: true },
        silentError : true,
      },
    ));
  } else {
    yield put(getAction(
      commands.sendMssWebEmailConnector,
      apiSections.MSS_SEND_SMTP_EMAIL,
      emailContent,
      {
        synchronous : true,
        contextExtra: { priority: true },
        silentError : true,
      },
    ));
  }
};

const flattenFolders = folders => folders.flatMap(folder => [folder, ...flattenFolders(folder.folders || [])]);

export const handleImapSyncMessages = function* (action) {
  const { command: { email, folderId } } = action;
  const imapSynchronous                  = yield select(isImapSynchronous);
  
  if (folderId) {
    yield put(getAction(
      commands.getSearchMessagesMSS,
      apiSections.MSS_SEARCH_MESSAGES,
      { email, folderId },
      {
        synchronous : imapSynchronous,
        subSection  : folderId,
        subConfig   : mssSubTypes.IMAP,
        contextExtra: { mssApiType: mssSubTypes.IMAP, forceAction: errorActions.NONE },
      },
    ));
  } else {
    yield put(getAction(
      commands.getFoldersMSS,
      apiSections.MSS_GET_FOLDERS,
      email,
      {
        synchronous : imapSynchronous,
        subConfig   : mssSubTypes.IMAP,
        contextExtra: { mssApiType: mssSubTypes.IMAP, forceAction: errorActions.NONE, emailAddress: email },
      },
    ));
    
    let folders = undefined;
    while (folders === undefined) {
      const {
              type, // only if error
              folders: result,
              emailAddress,
              context: { emailAddress: contextAddress } = {}
            } = yield take([
        dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_FOLDERS,
        dmpCommandFailureContextualizedType(apiSections.MSS_GET_FOLDERS),
      ]);
      
      if (type === dmpCommandFailureContextualizedType(apiSections.MSS_GET_FOLDERS)) {
        if (email === contextAddress) {
          folders = [];
        }
      } else {
        if (email === emailAddress) {
          folders = result;
        }
      }
    }
    
    const flatFolders = flattenFolders(folders || []);
    yield all(flatFolders.map(folder => put(getAction(
      commands.getSearchMessagesMSS,
      apiSections.MSS_SEARCH_MESSAGES,
      { email, folderId: folder.id },
      {
        synchronous : imapSynchronous,
        subSection  : folder.id,
        subConfig   : mssSubTypes.IMAP,
        contextExtra: { mssApiType: mssSubTypes.IMAP, forceAction: errorActions.NONE },
      },
    ))));
  }
};

const extractMessageContentFromParts = (messageParts, messageId) => {
  const contentPart = messageParts.find(({ Headers = [] }) => Headers.some(({
    s_name = '',
    s_value = ''
  }) => s_name === 'Content-Type' && s_value.indexOf('multipart/related') !== -1));
  if (contentPart) {
    const { s_contentInBase64: htmlContent = '' } = contentPart.Parts.find(({ Headers = [] }) => Headers.some(({ s_value = '' }) => s_value.indexOf('text/html') !== -1)) || {};
    const message  = b64DecodeUnicode(htmlContent);
    let extraParts = {};
    
    // remplacer les parties du message à part
    const replacements = contentPart.Parts.filter(({ Headers = [] }) => Headers.some(({
      s_name = '',
      s_value = ''
    }) => s_name === 'Content-Disposition' && s_value.indexOf('inline') !== -1));
    replacements.forEach((replacement) => {
      const { Headers, s_contentInBase64 }            = replacement;
      const { s_value: contentTypeHeader = '' }       = Headers.find(h => h.s_name === 'Content-Type') || {};
      const { s_value: contentTransferEncoding = '' } = Headers.find(h => h.s_name === 'Content-Transfer-Encoding') || {};
      const { s_value: contentIdHeader = '' }         = Headers.find(h => h.s_name === 'Content-ID') || {};
      
      const [contentType] = contentTypeHeader.split(';');
      
      // {
      //   s_name: 'Content-ID',
      //       s_value: '<31a1d792af4d8426f529d10f2a35ee91b0703ac5@zimbra>'
      // }
      // enlever les chevrons de début et de fin
      const contentId = contentIdHeader.substring(1, contentIdHeader.length - 1);
      // c'est une image
      if (contentType.indexOf('image') !== 1) {
        const image = `data:${contentType};${contentTransferEncoding},${s_contentInBase64}`;
        extraParts  = { ...extraParts, [`${contentId}`]: image };
      }
    });
    return { message, extraParts, isHtml: true };
  }
  const htmlPart = messageParts.find(({ Headers }) => Headers.some(({ s_value = '' }) => s_value.indexOf('text/html') !== -1));
  
  if (htmlPart) {
    return { message: b64DecodeUnicode(htmlPart.s_contentInBase64), isHtml: true };
  }
  const { s_contentInBase64: textContent = '' } = messageParts.find(({ Headers }) => Headers.some(({ s_value = '' }) => s_value.indexOf('text/plain') !== -1)) || {};
  return { message: b64DecodeUnicode(textContent), isHtml: false };
};

export const handleGetMessageContentResult = function* (action) {
  const {
          command: {
            s_uid: messageId,
          },
          data   : {
            Headers: messageHeaders,
            Parts,
            s_contentInBase64: mailContent,
          },
          context: {
            email,
          },
        } = action;
  
  let body;
  let extraParts;
  let isHTML;
  let attachments;
  
  if (!Parts) {
    body = b64DecodeUnicode(mailContent).replaceAll('\r\n', '<br/>');
  } else {
    // multipart/alternative ?
    const contentTypeMessageHeader = messageHeaders.find(h => h.s_name === 'Content-Type');
    const contentTypeMessageValue  = contentTypeMessageHeader ? contentTypeMessageHeader.s_value : undefined;
    
    let messageContent;
    if (contentTypeMessageValue) {
      if (contentTypeMessageValue.indexOf('multipart/alternative') !== -1) { // HTML avec images, sans PJ
        // multiPart related ? on doit alors reformer le message complet
        const { message, extraParts: extra, isHtml } = extractMessageContentFromParts(Parts, messageId);
        messageContent                               = message;
        extraParts                                   = extra;
        isHTML                                       = isHtml;
      } else if (contentTypeMessageValue.indexOf('multipart/mixed') !== -1) { // HTML, avec PJ
        const contentPart                            = Parts.find(({ Headers }) => Headers.some(({
          s_name = '',
          s_value = ''
        }) => s_name === 'Content-Type' && s_value.indexOf('multipart/alternative') !== -1));
        const { message, extraParts: extra, isHtml } = extractMessageContentFromParts(contentPart ? contentPart.Parts : Parts, messageId);
        messageContent                               = message;
        extraParts                                   = extra;
        isHTML                                       = isHtml;
      } else {
        const { s_contentInBase64: textContent = '' } = Parts.find(({ Headers }) => Headers.some(({ s_value = '' }) => s_value.indexOf('text/plain') !== -1)) || {};
        messageContent = b64DecodeUnicode(textContent);
        isHTML         = false;
      }
    }
    
    if (messageContent) {
      body = messageContent;
    } else {
      body = 'EFFICIENCE_NO_BODY';
    }
    
    const attachmentParts = Parts.filter(({ AttachmentInfos }) => AttachmentInfos && Array.isArray(AttachmentInfos));
    
    attachments = attachmentParts && attachmentParts.map((attachment) => {
      const { Headers, AttachmentInfos, s_contentInBase64 = '' } = attachment;
      const contentTypeHeader                               = Headers.find((header = {}) => header.s_name === 'Content-Type');
      return {
        contentType: contentTypeHeader ? contentTypeHeader.s_value : '',
        fileName   : AttachmentInfos[0].s_filename,
        part       : AttachmentInfos[0].s_filename,
        size       : s_contentInBase64.length,
        content    : s_contentInBase64,
      };
    });
  }
  
  const newContent = {};
  
  const replyToHeader = messageHeaders.find(h => h.s_name.toLowerCase() === 'reply-to');
  if (replyToHeader) {
    Object.assign(newContent, { replyTo: replyToHeader.s_value });
  }
  
  const messageIdHeader = messageHeaders.find(h => h.s_name.toLowerCase() === 'message-id');
  if (messageIdHeader) {
    Object.assign(newContent, {
      headerMessageId: messageIdHeader.s_value
        .replace('<', '')
        .replace('>', ''),
    });
  }
  
  const inReplyToHeader = messageHeaders.find(h => h.s_name.toLowerCase() === 'in-reply-to');
  if (inReplyToHeader) {
    Object.assign(newContent, {
      inReplyToMessageIds: inReplyToHeader.s_value
        .split(' ')
        .map(id => id.replace('<', '').replace('>', '')),
    });
  }
  
  const referencesHeader = messageHeaders.find(h => h.s_name.toLowerCase() === 'references');
  if (referencesHeader) {
    Object.assign(newContent, {
      references: referencesHeader.s_value
        .split(' ')
        .map(id => id.replace('<', '').replace('>', '')),
    });
  }
  
  if (body) {
    Object.assign(newContent, { body });
  }
  Object.assign(newContent, { extraParts });
  Object.assign(newContent, { isHTML });
  
  if (attachments) {
    Object.assign(newContent, {
      attachments: attachments.map(att => ({
        contentType: att.contentType,
        fileName   : att.fileName,
        part       : att.part,
        size       : att.size,
        content    : att.content,
      })),
    });
  }
  
  if (Object.keys(newContent).length > 0) {
    yield put({
      type: mssActionConstants.SET_MESSAGE_CONTENT,
      email,
      messageId,
      ...newContent,
    });
  }
};

export const handleDownloadAttachment = function* (attachment, messageId, mssEmail) {
  const {
          part, contentType, fileName, size, content,
        } = attachment;
  
  if (content) {
    return attachment;
  }
  
  yield put(getAction(
    commands.getDownloadAttachmentMSS,
    apiSections.MSS_DOWNLOAD_ATTACHMENT,
    { email: mssEmail, messageId, part },
    {
      subSection  : `${messageId}_${part}`,
      synchronous : true,
      contextExtra: { priority: true },
    },
  ));
  let result;
  
  while (!result) {
    const attachmentResult = yield take([
      mssActions.SET_ATTACHMENT_DOWNLOADED,
      dmpCommandFailureContextualizedType(apiSections.MSS_DOWNLOAD_ATTACHMENT),
    ]);
    
    if (attachmentResult.type === mssActions.SET_ATTACHMENT_DOWNLOADED) {
      const { attachment: { messageId: resultMessageId, part: resultPart } } = attachmentResult;
      if (messageId === resultMessageId && part === resultPart) {
        result = attachmentResult.attachment;
      }
    } else if (attachmentResult.type === dmpCommandFailureContextualizedType(apiSections.MSS_DOWNLOAD_ATTACHMENT)) {
      const { context: { subSection } } = attachmentResult;
      if (subSection === `${messageId}_${part}`) {
        result = false;
      }
    }
  }
  return {
    ...result, contentType, fileName, size,
  };
};

export const handleDownloadAllAttachments = function* (attachments, messageId, mssEmail) {
  return yield all(attachments.map(att => call(handleDownloadAttachment, att, messageId, mssEmail)));
};

const generateZip = async zip => zip.generateAsync({ type: 'base64' });

export const handleDownloadAndZipAllAttachments = function* ({
  attachments, messageId, mssEmail, subject,
}) {
  const downloaded = yield call(handleDownloadAllAttachments, attachments, messageId, mssEmail);
  const zip        = new JSZip();
  
  downloaded.forEach((attachment) => {
    zip.file(attachment.fileName, attachment.content, { base64: true });
  });
  
  const zipResult = yield call(generateZip, zip);
  yield put(setMssDownloadedAttachment({
    messageId,
    part       : 'efficience-zip-all',
    content    : zipResult,
    contentType: 'application/zip',
    fileName   : subject.replace(/[\W_]+/g, ''),
    email      : mssEmail,
  }));
};

const getPsInfosFromState = (state, email) => {
  const {
          dmpconnect: {
            [apiSections.MSS_GET_HP_INFOS]: {
              [email]: getHpSection = {},
            } = {},
          },
        } = state;
  
  return getHpSection;
};

function* getPsInfosFromMail(emailAddress) {
  if (emailAddress) {
    const hpInfos = yield select(getPsInfosFromState, emailAddress);
    if (!isReady(hpInfos) && !hasError(hpInfos) && !isLoading(hpInfos)) {
      yield put(getAction(
        commands.getMssHpInfos,
        apiSections.MSS_GET_HP_INFOS,
        {
          Query: {
            s_type          : 'EQUAL',
            s_attributeName : 'mail',
            s_attributeValue: emailAddress,
          },
        },
        {
          synchronous: true,
          subSection : emailAddress,
          silentError: true,
        },
      ));
    }
  }
}

function* getPatientInfosFromMail(emailAddress) {
  if (emailAddress) {
    const isPatientEmail = isMssPatientEmail(emailAddress);
    if (isPatientEmail) {
      const [ins]        = emailAddress.split('@');
      const typedIns     = `${ins}${Number(env.REACT_APP_PRODUCTON_MODE) === 1 ? insTypes.REAL_INS : insTypes.TEST_INS}`;
      const patientInfos = yield select(getDirectAuthenticationStatus, typedIns);
      if (!isReady(patientInfos) && !hasError(patientInfos) && !isLoading(patientInfos)) {
        const apiEnabled = yield select(getConfigMssPatientInfosApiEnable);
        if (apiEnabled === true) {
          postMessageToIframeParent({
            type: 'mss_patient_infos_request',
            ins : typedIns,
          });
        } else {
          yield put(getDirectAuthenticationDMPStatus(typedIns, null, true));
          let result;
          const { accessRights: { psId } } = yield select(getAccessRightsProps);
          const subSectionID               = `${typedIns}/${psId}`;
          while (result === undefined) {
            const directResult                = yield take([
              dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
              dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
            ]);
            const { context: { subSection } } = directResult;
            
            if (subSection === subSectionID) {
              result = directResult;
            }
          }
          const directSection = yield select(getDirectAuthenticationStatus, typedIns);
          const infos         = getSelectedPatientInfos(directSection);
          yield put(setMssPatientInfos({
            ins  : typedIns,
            given: infos.givenName,
            name : infos.name || infos.birthName,
          }));
        }
      } else if (isReady(patientInfos)) {
        const infos = getSelectedPatientInfos(patientInfos);
        yield put(setMssPatientInfos({
          ins  : typedIns,
          given: infos.givenName,
          name : infos.name || infos.birthName,
        }));
      }
    }
  }
}

function* checkAndGetPeopleInfosFromMessage(message) {
  const {
          to,
          from,
        } = message;
  
  const [recipient] = to;
  if (recipient) {
    yield call(getPsInfosFromMail, recipient.email);
    yield call(getPatientInfosFromMail, recipient.email);
  }
  
  const [sender] = from;
  if (sender) {
    // if (sender.email === 'louis.radiocabrpps0034618@medecin.formation.mssante.fr') {
    //   sender.email = '146026322000196@patient.mssante.fr';
    // }
    yield call(getPsInfosFromMail, sender.email);
    yield call(getPatientInfosFromMail, sender.email);
  }
}

export const getPeopleInfosAfterReceiveMessages = function* (action) {
  const { messages: { modifiedMessages = [], allMessages = [] } } = action;
  const messages                                                  = [...modifiedMessages, ...allMessages];
  
  for (let i = 0; i < messages.length; i += 1) {
    yield call(checkAndGetPeopleInfosFromMessage, messages[i]);
  }
};

export const handleDeleteMssFolder = function* ({
  mssEmail, folder, mssApiType, toastId,
}) {
  if (folder.folders && folder.folders.length > 0) {
    yield all(folder.folders.map(subFolder => call(handleDeleteMssFolder, { mssEmail, folder: subFolder, mssApiType })));
  }
  yield put(getAction(
    commands.deleteFolderMSS,
    apiSections.MSS_DELETE_FOLDERS,
    {
      email   : mssEmail,
      folderId: folder.id,
    },
    {
      subConfig   : mssApiType,
      contextExtra: {
        mssApiType, email: mssEmail, toastId, priority: true, forceAction: errorActions.NONE,
      },
      synchronous : true,
    },
  ));
  yield take([
    dmpCommandSuccessContextualizedType(apiSections.MSS_DELETE_FOLDERS),
    dmpCommandFailureContextualizedType(apiSections.MSS_DELETE_FOLDERS),
  ]);
};

export const handleRefreshFoldersList = function* (action) {
  const { context: { mssApiType, email } } = action;
  
  yield put(getAction(
    commands.getFoldersMSS,
    apiSections.MSS_GET_FOLDERS,
    email,
    {
      subConfig   : mssApiType,
      contextExtra: { mssApiType, forceAction: errorActions.NONE },
      synchronous : true,
    },
  ));
};

export const handleDirectoryManagementResult = (action) => {
  const {
          context: {
            section, toastId,
          }, data: { s_status },
        } = action;
  
  if (toastId) {
    if (s_status === 'OK') {
      let toastMessage;
      if (section === apiSections.MSS_CREATE_FOLDERS) {
        toastMessage = 'Dossier créé avec succès';
      } else if (section === apiSections.MSS_DELETE_FOLDERS) {
        toastMessage = 'Dossier supprimé avec succès';
      } else if (section === apiSections.MSS_RENAME_FOLDERS) {
        toastMessage = 'Dossier renommé avec succès';
      }
      
      toast.update(toastId, { type: 'success', render: toastMessage, autoClose: 3000 });
    } else {
      toast.update(toastId, { autoClose: 1 });
    }
  }
};

export const handleMssCertsChange = function* (action) {
  const
    {
      account: {
        esMssCertificate,
        esMssImapCertificate,
        esMssSmtpCertificate,
        esMssWsCertificate,
      } = {},
      handleCerts,
    } = action;
  
  if (handleCerts) {
    if (esMssCertificate || esMssWsCertificate) {
      yield put(getAction(
        commands.setMssEsWebServiceCertificate,
        apiSections.MSS_SET_WS_CERT,
        esMssCertificate && !esMssWsCertificate
        ? esMssCertificate
        : esMssWsCertificate,
        {
          silentError: true,
        },
      ));
    }
    
    if (esMssCertificate || esMssImapCertificate) {
      yield put(getAction(
        commands.setMssEsImapCertificate,
        apiSections.MSS_SET_IMAP_CERT,
        esMssCertificate && !esMssImapCertificate
        ? esMssCertificate
        : esMssImapCertificate,
        {
          silentError: true,
        },
      ));
    }
    
    if (esMssCertificate || esMssSmtpCertificate) {
      yield put(getAction(
        commands.setMssEsSmtpCertificate,
        apiSections.MSS_SET_SMTP_CERT,
        esMssCertificate && !esMssSmtpCertificate
        ? esMssCertificate
        : esMssSmtpCertificate,
        {
          silentError: true,
        },
      ));
    }
  }
}

export const checkMssLoginType = function* () {
  // check mss config from app-config here
  // we cannot display an error from src/reducers/configFromEnv.js
  if (env.REACT_APP_MSS_OPERATORS) {
    try {
      if (env.REACT_APP_MSS_OPERATORS) {
        const value = JSON.parse(env.REACT_APP_MSS_OPERATORS);
        mssOperatorsConfigValidator.validateSync(value, { abortEarly: false });
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        const details    = [
          createErrorDetails('Errors', e.inner.map(error => ({
            field: error.path,
            value: error.value,
            error: error.message,
          }))),
          createErrorDetails('provided', JSON.parse(env.REACT_APP_MSS_OPERATORS)),
        ];
        const modalError = createModalError({
          i_apiErrorType: errorTypes.SoftwareErrors,
          i_apiErrorCode: softwareErrors.INVALID_MSS_OPERATORS_CONFIG,
        }, details);
        
        yield put(setModalError(modalError));
      } else {
        const details    = createErrorDetails('Error', e.message);
        const modalError = createModalError({
          i_apiErrorType: errorTypes.SoftwareErrors,
          i_apiErrorCode: softwareErrors.INVALID_MSS_OPERATORS_CONFIG,
        }, [details]);
        yield put(setModalError(modalError));
      }
      console.error('===============================');
      console.error('e', e);
      console.error('REACT_APP_MSS_OPERATORS', e.message);
      console.error('===============================');
    }
  }
  
  const mssApiType                               = yield select(getConfigMssApiType);
  const mssLoginType                             = yield select(getConfigMssLoginType);
  const mssOperator                              = yield select(getConfigMssOperator);
  const apiType                                  = yield select(getApiType);
  const { accessRights: { authenticationType } } = yield select(getAccessRightsProps);
  
  if (!env.REACT_APP_MSS_LOGIN_TYPE && !mssLoginType) {
    if (apiType === API_TYPES.REST && mssApiType === mssSubTypes.IMAP) {
      yield put(setMssConfiguration('mssLoginType', mssLoginTypes.CERT));
    } else if (apiType === API_TYPES.REST && mssApiType === mssSubTypes.WEB) {
      yield put(setMssConfiguration('mssLoginType', mssLoginTypes.OTP));
    } else if (authenticationType === authenticationTypes.INDIRECT) {
      yield put(setMssConfiguration('mssLoginType', mssLoginTypes.OTP));
    } else {
      yield put(setMssConfiguration('mssLoginType', mssLoginTypes.CPX));
    }
  }
  
  if (!mssOperator) {
    if (mssLoginType === mssLoginTypes.PSC) {
      yield put(setMssConfiguration('mssOperator', 'mailiz-imap-psc'));
    } else if (mssLoginType === mssLoginTypes.CERT && mssApiType === mssSubTypes.IMAP) {
      yield put(setMssConfiguration('mssOperator', 'mailiz-imap-cert'));
    } else if ((mssLoginType === mssLoginTypes.OTP || apiType === API_TYPES.REST) && mssApiType === mssSubTypes.WEB) {
      yield put(setMssConfiguration('mssOperator', 'mailiz-web-otp'));
    } else {
      yield put(setMssConfiguration('mssOperator', 'mailiz-imap-cpx'));
    }
  }
};

export const setMssAuthenticationId = function* (action) {
  const { data: { s_authenticationId } } = action;
  yield put(setPersistedAppConfiguration('mssAuthenticationId', s_authenticationId));
};

export const handleAppendMessageToSentDirectory = function* (action) {
  const { data: { s_rawMessageSentInBase64 } } = action;
  
  const mssApiType      = yield select(getConfigMssApiType);
  const mssEmail        = yield select(getMssEmail);
  // const mssFolders = yield select(getMssFolders, mssEmail);
  const imapSynchronous = yield select(isImapSynchronous);
  if (s_rawMessageSentInBase64) {
    // removed this conditions, if we never opened the mailbox, folders don't exist
    // && mssFolders
    // const sentFolder = mssFolders.find(f => f.type === mssFolderTypes.SENT);
    if (mssApiType === mssSubTypes.IMAP) {
      const { data: { UniqueIds, DocumentsContent } = {} } = action;
      const dataExtra                                      = { UniqueIds, DocumentsContent };
      console.log('===============================');
      console.log('dataExtra', dataExtra);
      console.log('===============================');
      
      yield put(getAction(
        commands.imapAppendMessage,
        apiSections.MSS_IMAP_APPEND_MESSAGE,
        {
          email          : mssEmail,
          folderName     : 'Sent',
          messageInBase64: s_rawMessageSentInBase64,
        },
        {
          subConfig    : mssSubTypes.IMAP,
          synchronous  : imapSynchronous,
          contextExtra : { priority: true, mssApiType: mssSubTypes.IMAP, forceAction: errorActions.NONE },
          contextParams: { mssEmail },
          dataExtra,
          silentError  : true,
        },
      ));
    } else {
      yield put({ ...action, type: dmpCommandSuccessContextualizedType(apiSections.MSS_IMAP_APPEND_MESSAGE) });
    }
  } else {
    yield put({ ...action, type: dmpCommandSuccessContextualizedType(apiSections.MSS_IMAP_APPEND_MESSAGE) });
  }
};

export const setMssPscAccessToken = function* ({ token }) {
  if (token && token.access_token) {
    yield put(setMssConfiguration('mssPscAccessToken', token.access_token));
  } else {
    yield put(setMssConfiguration('mssPscAccessToken', undefined));
  }
};

export const handleRefreshSentFolder = function* (action) {
  const mssApiType      = yield select(getConfigMssApiType);
  const mssEmail        = yield select(getMssEmail);
  const imapSynchronous = yield select(isImapSynchronous);
  
  yield put(getAction(
    commands.getSearchMessagesMSS,
    apiSections.MSS_SEARCH_MESSAGES,
    { email: mssEmail, folderId: 'Sent' },
    {
      subSection  : 'Sent',
      subConfig   : mssApiType,
      contextExtra: { mssApiType },
      synchronous : imapSynchronous,
    },
  ));
};

export const handleRefreshRenamedFolder = function* (action) {
  const mssApiType      = yield select(getConfigMssApiType);
  const mssEmail        = yield select(getMssEmail);
  const imapSynchronous = yield select(isImapSynchronous);
  
  const { command: { s_newFolderName } } = action;
  
  yield put(getAction(
    commands.getSearchMessagesMSS,
    apiSections.MSS_SEARCH_MESSAGES,
    { email: mssEmail, folderId: s_newFolderName },
    {
      subSection  : s_newFolderName,
      subConfig   : mssApiType,
      contextExtra: { mssApiType },
      synchronous : imapSynchronous,
    },
  ));
  yield put(push(`/mss/${encodeURIComponent(s_newFolderName)}`));
};
