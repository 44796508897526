import { setModalError, setMssConfiguration } from 'dmpconnectjsapp-base/actions';
import commands                               from 'dmpconnectjsapp-base/actions/config/commands';
import getCommandAction, {
  doWeCheckCpxToken
}                                             from 'dmpconnectjsapp-base/actions/config/generateCommands';
import {
  API_TYPES, apiSections, dmpconnectActionConstants
}                                             from 'dmpconnectjsapp-base/constants';
import {
  createError, errorTypes, jwtErrors
}                                             from 'dmpconnectjsapp-base/errors';
import {
  getApiType, getConfigurationValue, getEsUser, getInteropCodesFromState, getSessionId, isAirActive, isUserLoggedIn,
}                                             from 'dmpconnectjsapp-base/helpers/accessors';
import {
  isReady
}                                             from 'dmpconnectjsapp-base/helpers/common';
import {
  formatSearchParams
}                                             from 'dmpconnectjsapp-base/helpers/findDocuments';
import {
  esLoginTypes
}                                             from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import {
  authenticationTypes, getAccessRightsProps
}                                             from 'dmpconnectjsapp-base/rules/accessRights';
import env                                    from '../../envVariables';
import {
  exceptionRoutes
}                                             from '../../router';
import {
  dmpconnectApplicationActionConstants, dpActionsConstants
}                                             from '../constants';
import {
  createErrorDetails, createModalError, getErrorDescription
}                                             from '../errors';
import {
  errorActions
}                                             from '../errors/errorActions';
import {
  jsonWenTokenErrorsToJwtErrors
}                                             from '../errors/errorConstants';
import {
  getDirectAuthenticationStatus
}                                             from '../helpers/directAuthenticationDMPStatus';
import {
  verifyAndDecodeJWT
}                                             from '../helpers/jwtUtils';
import {
  verifyJwtPromise
}                                             from '../sagas/remoteSagas';
import {
  generateId, generateUniqueId, getCurrentPathname
}                                             from '../utils/dataUtils';
import {
  postMessageToIframeParent
}                                             from '../utils/iframe';
import {
  sendRemoteOutMessage
}                                             from './dmpconnectRemoteActions';
import {
  setLoginReferer, setUserJWT
}                                             from './dmpconnectUserActions';

export const getAction = (
  commandName,
  section,
  values,
  {
    synchronous = false,
    contextParams = {},
    contextExtra = {},
    dataExtra = {},
    subSection = '',
    forceEs = false,
    silentError,
    sessionId = null,
    subConfig = null,
    remote = false,
  } = {},
) => (async (dispatch, getState) => {
  // check the token if needed
  const {
          dmpconnectESConfiguration          : {
            loginType
          } = {},
          dmpConnectPersistedAppConfiguration: {
            cpxLoginType,
            externalPscAccessToken,
          },
          dmpconnectUser                     : {
            userJWT,
          },
          dmpconnectApplication              : {
            timeSynchronizationDifference,
          }
        } = getState();
  
  if (cpxLoginType === 'OPENID' && externalPscAccessToken && doWeCheckCpxToken(commandName, getState()) && userJWT && !!userJWT.access_token) {
    
    const { err } = await verifyJwtPromise(userJWT.access_token, timeSynchronizationDifference);
    
    if (err) {
      dispatch(setMssConfiguration('mssPscAccessToken', ''));
      dispatch(setUserJWT(undefined));
      dispatch(sendRemoteOutMessage({
        request: {
          '@data': 'pscAccessToken',
          '@id'  : `{${generateUniqueId().toUpperCase()}}`,
          details: [{ ...err }],
        },
      }));
      if (!exceptionRoutes.includes(getCurrentPathname())) {
        dispatch(setLoginReferer(getCurrentPathname()));
      }
      const error      = createError(errorTypes.JWTErrors, jsonWenTokenErrorsToJwtErrors[err.name] || jwtErrors.INVALID_TOKEN);
      const modalError = createModalError(
        { ...error, forceAction: errorActions.NONE },
        [createErrorDetails('Erreur détaillée', { detailsType: 'error', ...error })],
      );
      return dispatch(setModalError(modalError));
    }
  }
  
  const air = isAirActive(getState());
  if (air) {
    const loggedIn = isUserLoggedIn(getState());
    const esUser   = getEsUser(getState());
    
    if (loginType === esLoginTypes.TOKEN && loggedIn && !esUser.doNotValidateToken) {
      if (esUser.token) {
        const decodedToken = verifyAndDecodeJWT(esUser.token, timeSynchronizationDifference);
        if (!decodedToken.valid) {
          const modalError = createModalError(
            decodedToken.error,
            [createErrorDetails('Erreur détaillée', { detailsType: 'error', ...decodedToken.error })],
          );
          postMessageToIframeParent({
            type: 'invalid_token',
            desc: getErrorDescription(decodedToken.error).message,
          });
          return dispatch(setModalError(modalError));
        }
      } else {
        const error      = createError(errorTypes.JWTErrors, jwtErrors.EMPTY_TOKEN);
        const modalError = createModalError(
          error,
          [createErrorDetails('Erreur détaillée', { detailsType: 'error', ...error })],
        );
        postMessageToIframeParent({
          type: 'empty_token',
          desc: getErrorDescription(error).message,
        });
        return dispatch(setModalError(modalError));
      }
    }
  }
  return dispatch(getCommandAction(
    commandName,
    section,
    values,
    {
      synchronous,
      contextParams,
      contextExtra,
      dataExtra,
      subSection,
      forceEs,
      silentError,
      sessionId,
      subConfig,
      remote,
    },
  ));
});

export const readCpxProcess = (pinCode, readerNumber, doLogin = true, forceLoginStepTwo = false) => ({
  type: dmpconnectActionConstants.DMPC_READ_CPX_PROCESS,
  pinCode,
  readerNumber,
  doLogin,
  forceLoginStepTwo,
});

export const selectINS = (ins) => {
  let tmpIns = ins;
  if (ins === undefined || ins === '' || ins === 0) {
    tmpIns = '-1';
  }
  
  return {
    type: dmpconnectActionConstants.DMPC_SELECT_INS,
    ins : tmpIns,
  };
};

export const selectCertifiedIdentity = subSection => ({
  type             : dmpconnectActionConstants.DMPC_SELECT_CERTIFIED_ID,
  certifiedIdentity: subSection,
});

export const requestPcscReaders = synchronous => ({
  type: dmpconnectActionConstants.DMPC_REQUES_PCSC_READERS,
  synchronous,
});
export const getRestPcscReaders = () => ({
  type: dmpconnectActionConstants.DMPC_GET_REST_PCSC_READERS,
});

export const getDirectAuthenticationDMPStatus = (ins, performerId = null, override = true) => (dispatch, getState) => {
  const sessionId = getSessionId(getState());
  const apiType   = getApiType(getState());
  
  if (override === false) {
    if (isReady(getDirectAuthenticationStatus(getState(), ins))) {
      return;
    }
  }
  
  if (!sessionId && apiType !== API_TYPES.REST) {
    return;
  }
  
  const {
          accessRights: {
            psId, esId, authenticationType, airOnly,
          },
        } = getAccessRightsProps(getState());
  
  if (!performerId || (performerId && performerId === psId)) {
    dispatch(
      getAction(
        authenticationType === authenticationTypes.DIRECT
        ? commands.getDirectAuthenticationDMPStatus
        : commands.getDMPStatus,
        apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION,
        { s_ins: ins },
        {
          subSection   : `${ins}/${psId}`,
          contextParams: { ins, performer: psId },
        },
      ),
    );
  }
  
  if (
    authenticationType === authenticationTypes.AIR
    && !airOnly
    && (
      !performerId || (performerId && performerId === esId)
    )
  ) {
    dispatch(
      getAction(
        authenticationType === authenticationTypes.DIRECT
        ? commands.getDirectAuthenticationDMPStatus
        : commands.getDMPStatus,
        apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION,
        { s_ins: ins },
        {
          subSection   : `${ins}/${esId}`,
          contextParams: { ins, performer: esId },
          forceEs      : true,
        },
      ),
    );
  }
};
export const sendDocument                     = (
  ins,
  category,
  base64Content,
  title,
  description          = '',
  format,
  visibility,
  healthcareSetting,
  creationDate,
  replacedDocumentId   = '',
  replacedDocumentUUid = '',
  serviceStartDate,
  serviceStopDate,
  identity,
) => (
  (dispatch, getState) => {
    const {
            dmpConnectPersistedAppConfiguration: {
              forceSchematronsValidation = false,
              ignorePdfA1Transparency    = false,
              disabledPdfA1Conversion    = false,
            },
            dmpconnectApplication              : {
              submitEngine,
            },
          } = getState();
    
    dispatch(
      getAction(
        commands.sendDocument,
        apiSections.SEND_DOCUMENT_SECTION,
        {
          ins,
          base64Content,
          title,
          description,
          category,
          visibility,
          format,
          healthcareSetting,
          creationDate,
          serviceStartDate,
          serviceStopDate,
          replacedDocumentId,
          esUser              : getEsUser(getState()),
          forceSchematronsValidation,
          ignorePdfA1Transparency,
          disabledPdfA1Conversion,
          retrieveDocumentUuid: 1,
          Identity            : identity,
          transcodeTypecode   : Number(env.REACT_APP_PRODUCTON_MODE) === 1 ? 0 : 1,
          submitEngine,
        },
        {
          contextExtra: { replacedDocumentUUid },
          silentError : true,
        },
      ),
    );
  }
);

export const findDocuments = (ins, params) => (dispatch, getState) => {
  const docTypesTranslation                                = getInteropCodesFromState(getState(), 'docTypesTranslation');
  const { dmpconnectConnectorConfig }                      = getState();
  const { debugActivateDocumentCache: enableCache = true } = dmpconnectConnectorConfig;
  dispatch(getAction(
    commands.findDocuments,
    apiSections.FIND_DOCUMENTS_SECTION,
    formatSearchParams({ ...params, ins }),
    {
      contextParams: {
        ...params, s_ins: ins,
      },
      contextExtra : {
        enableCache, docTypesTranslation,
      },
      subSection   : ins,
    },
  ));
};

export const manageDMP = (action, ins, params, generateOtp = false) => ({
  type: dmpconnectActionConstants.DMPC_MANAGE_DMP_PROCESS,
  action,
  ins,
  params,
  generateOtp,
});

export const requestWebPsUrls = ins => ({
  type   : dmpconnectActionConstants.DMPC_GET_WEB_PS_URLS,
  context: {
    section   : apiSections.WEB_PS_URLS,
    subSection: ins,
  },
});

export const setWebPsRootUrls = () => (dispatch, getState) => {
  const { dmpconnectConnectorConfig } = getState();
  const webPsRootUrl                  = getConfigurationValue('webPsRootUrl', dmpconnectConnectorConfig);
  return dispatch(getAction(
    commands.setWebPsRootUrl,
    apiSections.WEB_PS_URLS,
    { s_url: webPsRootUrl },
    {
      subSection : 'setRootUrl',
      synchronous: true,
    },
  ));
};

export const removeDocFromPersistantData = (uniqueId, uuid, ins) => ({
  type: dmpconnectActionConstants.DMPC_REMOVE_DOC_FROM_PERISTANT_DATA,
  uniqueId,
  uuid,
  ins,
});
export const sendDocToPersistantData     = (
  ins, base64Content, title, description, category,
  visibility, format, healthcareSetting, replacedDocumentId, psId,
) => ({
  type   : dmpconnectActionConstants.DMPC_SEND_DOC_TO_PERISTANT_DATA,
  command: {
    s_ins                     : ins,
    s_fileContentBase64       : base64Content,
    s_documentTitle           : title,
    s_documentDescription     : description,
    s_documentCategory        : category,
    i_documentVisibility      : visibility,
    i_documentFormat          : format,
    s_healthcareSetting       : healthcareSetting,
    s_replacedDocumentUniqueId: replacedDocumentId,
    s_hpInternalId            : psId,
  },
  data   : {
    s_uniqueId: generateId(20),
  },
});

export const exportCdaContent = (ins, uniqueUUid, content) => ({
  type: dmpconnectApplicationActionConstants.DMPC_EXPORT_CDA_CONTENT,
  ins,
  uniqueUUid,
  content,
});

export const getDPStatus = (infos, consultType) => ({
  type: dpActionsConstants.DMPC_GET_DP_STATUS,
  infos,
  consultType,
});

export const getInsFromVitaleCard = action => ({
  type: dmpconnectApplicationActionConstants.DMPC_GET_INS_FROM_VITALE_CARD,
  ...action,
});

export const checkAndGetINSiIdentity = action => ({
  type: dmpconnectApplicationActionConstants.DMPC_CHECK_AND_GET_INS,
  ...action,
});
