import ClassicEditor                                                                           from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import { CKEditor }                                                                            from '@ckeditor/ckeditor5-react';
import { clearSection, clearSubSection }                                                       from 'dmpconnectjsapp-base/actions';
import {
  mssSubTypes
}                                                                                              from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections, mssLoginTypes }                                                          from 'dmpconnectjsapp-base/constants';
import {
  getActiveMssAccountConfig, getApiType, getConfigurationValue, getHealthcareSetting, getMssAccountConfigByEmail,
  isAirActive, isMssCertReady, isMssCpxReady, isMssOTPReady,
  isMssPSCReady,
}                                                                                              from 'dmpconnectjsapp-base/helpers/accessors';
import { getError, hasError, isLoading, isReady, }                                             from 'dmpconnectjsapp-base/helpers/common';
import {
  getAccessRightsProps, isTransactionAllowed, transactions
}                                                                                              from 'dmpconnectjsapp-base/rules/accessRights';
import {
  checkDMPConnectJSVersion
}                                                                                              from 'dmpconnectjsapp-base/rules/systemRules';
import mime                                                                                    from 'mime-types';
import moment                                                                                  from 'moment';
import * as PropTypes                                                                          from 'prop-types';
import React                                                                                   from 'react';
import { Col, Row }                                                                            from 'react-bootstrap';
import { Highlighter, Menu, MenuItem, Typeahead, }                                             from 'react-bootstrap-typeahead';
import Button                                                                                  from 'react-bootstrap/Button';
import Form                                                                                    from 'react-bootstrap/Form';
import Platform                                                                                from 'react-platform-js';
import { connect }                                                                             from 'react-redux';
import { toast }                                                                               from 'react-toastify';
import { resetMssEmailContent, setShowMssPopup }                                               from '../../dmpconnect/actions';
import {
  remoteSendMssMessageRefused
}                                                                                              from '../../dmpconnect/actions/dmpconnectRemoteActions';
import { API_TYPES }                                                                           from '../../dmpconnect/constants';
import {
  insOidToType
}                                                                                              from '../../dmpconnect/constants/dmpConstants';
import { stepsStatus }                                                                         from '../../dmpconnect/constants/statuses';
import {
  getAccessibleDMPListCache, getFullName
}                                                                                              from '../../dmpconnect/helpers/accessibleDMPList';
import { formatMssMessageSubject, generateMessageId, getSendMssEmailAction, isMssTokenValid, } from '../../dmpconnect/helpers/mss';
import { calculateVisibility }                                                                 from '../../dmpconnect/rules/documentRules';
import { getDocumentFormatByExtension, isEmailValid }                                          from '../../dmpconnect/utils/dataUtils';
import ButtonWithLoader                                                                        from '../Common/Form/ButtonWithLoader';
import CustomTypeahead                                                                         from '../Common/Form/Input/CustomTypeahead';
import InputFileWithTokens
                                                                                               from '../Common/Form/Input/InputFileWithTokens';
import Alert                                                                                   from '../Common/Message/Alert';
import Popable                                                                                 from '../Common/Modal/Popable';
import StepStatuses                                                                            from '../Common/SectionStatus/StepStatuses';
import TitleTooltip                                                                            from '../Common/TitleTooltip';
import AddressEmailAsyncTypeAhead                                                              from './AddressEmailAsyncTypeAhead';
import MssConfiguration                                                                        from './MssConfiguration';
import MssDocumentSelector                                                                     from './MssDocumentSelector';


const renderPatientAutoComplete = (results, menuProps, state) => {
  const items = results.map((patient, index) => (
    <MenuItem key={`patientAutoComplete_${patient.s_ins}`} option={patient} position={index}>
      <Highlighter search={state.text}>
        {`${getFullName(patient.s_patientName, patient.s_patientFirstBirthGiven, patient.s_patientBirthName)} (${patient.s_ins})`}
      </Highlighter>
    </MenuItem>
  ));
  
  return <Menu {...menuProps}>{items}</Menu>;
};

const attachementTypes = { patient: 'patient', other: 'other' };

const patientSort = (a, b) => {
  const fullNameA = `${getFullName(a.s_patientName, a.s_patientFirstBirthGiven, a.s_patientBirthName)} (${a.s_ins})`;
  const fullNameB = `${getFullName(b.s_patientName, b.s_patientFirstBirthGiven, b.s_patientBirthName)} (${b.s_ins})`;
  
  return fullNameA < fullNameB ? -1 : 1;
};

const steps = {
  sending              : 'sending',
  generatingAttachments: 'generatingAttachments',
  copying              : 'copying',
};

// eslint-disable-next-line react/prop-types
const StepsStatusComponent = ({ processingSteps = [] }) => {
  // eslint-disable-next-line react/prop-types
  const sending               = processingSteps.find(step => step.step === steps.sending);
  // eslint-disable-next-line react/prop-types
  const copying               = processingSteps.find(step => step.step === steps.copying);
  // eslint-disable-next-line react/prop-types
  const generatingAttachments = processingSteps.find(step => step.step === steps.generatingAttachments);
  
  return (
    <>
      <StepStatuses
        step={sending}
        loadingMessage="Envoi de l'email"
        errorMessage="Erreur lors de l'envoi"
        successMessage="Email envoyé"
      />
      <StepStatuses
        step={generatingAttachments}
        loadingMessage="Génération des pièces-jointes"
        errorMessage="Erreur lors de la génération des pièces-jointes"
        successMessage="Pièces-jointes générées"
      />
      <StepStatuses
        step={copying}
        loadingMessage="Copie de l'email dans le dossier Envoyés"
        errorMessage="Erreur lors de la copie dans le dossier Envoyés"
        successMessage="Email copié dans le dossier Envoyés"
      />
    </>
  );
};

const MssSendEmail = ({
  dispatch,
  mssEmail,
  mssContent,
  sendEmailSection,
  appendMessageSection,
  generateAttachmentsSection,
  apiType,
  esUser,
  mssReceiptNotificationType,
  connectorVersion,
  ins,
  accessibleDMPList,
  healthcareSetting,
  mssApiType,
  hpAuthenticationMode,
  
  forceSchematronsValidation,
  ignorePdfA1Transparency,
  disabledPdfA1Conversion,
  
  mssSenderWording,
  mssReplyTo,
  mssLoginType,
  mssCpxReady,
  mssOTPReady,
  mssCertReady,
  mssPscReady,
  
  mssClientMode,
  externalPscAccessToken,
  mssPscAccessToken,
  categories,
}) => {
  const [selectedPatient, setSelectedPatient]         = React.useState();
  const [patientIns, setPatientIns]                   = React.useState(ins);
  const [patientAttachments, setPatientAttachments]   = React.useState(mssContent.attachments || []);
  const [otherAttachments, setOtherAttachments]       = React.useState(mssContent.otherAttachments || []);
  const [showCC, setShowCC]                           = React.useState(!!mssContent.cc);
  const [showBCC, setShowBCC]                         = React.useState(!!mssContent.bcc);
  const [subject, setSubject]                         = React.useState(mssContent.title);
  const [readonlySubject, setReadonlySubject]         = React.useState(false);
  const [message, setMessage]                         = React.useState(mssContent.messageContent);
  const [recipientsList, setRecipientsList]           = React.useState(
    mssContent.recipients
    ? mssContent.recipients.split(';')
      .map(recipient => ({ s_name: recipient.trim(), s_mail: recipient.trim() }))
    : [],
  );
  const [carbonCopyList, setCarbonCopyList]           = React.useState(
    mssContent.cc
    ? mssContent.cc.split(';')
      .map(recipient => ({ s_name: recipient.trim(), s_mail: recipient.trim() }))
    : [],
  );
  const [blindCarbonCopyList, setBlindCarbonCopyList] = React.useState(
    mssContent.bcc
    ? mssContent.bcc.split(';')
      .map(recipient => ({ s_name: recipient.trim(), s_mail: recipient.trim() }))
    : [],
  );
  const [processingSteps, setProcessingSteps]         = React.useState([]);
  
  const [sendReceiptNotification, setSendReceiptNotification]     = React.useState(!!mssContent.notificationReceiver);
  const [showNotificationReceivers, setShowNotificationReceivers] = React.useState(false);
  const [notificationReceivers, setNotificationReceivers]         = React.useState(
    mssContent.notificationReceiver
    ? [{ s_mail: mssContent.notificationReceiver, s_name: mssContent.notificationReceiver }]
    : [],
  );
  
  const updateStep = React.useCallback((section, step) => {
    if (isLoading(section)) {
      setProcessingSteps(previousSteps => [
        ...previousSteps.filter(s => s.step !== step),
        { step, status: stepsStatus.loading },
      ]);
    } else if (hasError(section)) {
      setProcessingSteps(previousSteps => [
        ...previousSteps.filter(s => s.step !== step),
        { step, status: stepsStatus.error, error: getError(section) },
      ]);
    } else if (isReady(section)) {
      setProcessingSteps(previousSteps => [
        ...previousSteps.filter(s => s.step !== step),
        { step, status: stepsStatus.success },
      ]);
    } else {
      setProcessingSteps(previousSteps => previousSteps.filter(s => s.step !== step));
    }
  }, [setProcessingSteps]);
  
  React.useEffect(() => {
    updateStep(sendEmailSection, steps.sending);
  }, [isLoading(sendEmailSection), isReady(sendEmailSection), hasError(sendEmailSection)]);
  
  React.useEffect(() => {
    if (mssContent.isRemote !== true) {
      updateStep(appendMessageSection, steps.copying);
    }
  }, [isLoading(appendMessageSection), isReady(appendMessageSection), hasError(appendMessageSection), mssContent.isRemote]);
  
  React.useEffect(() => {
    updateStep(generateAttachmentsSection, steps.generatingAttachments);
  }, [isLoading(generateAttachmentsSection), isReady(generateAttachmentsSection), hasError(generateAttachmentsSection)]);
  
  React.useEffect(() => {
    if (patientIns) {
      const {
              insiIdentity: {
                s_birthName,
                s_given,
                s_birthGiven,
                s_birthDate,
              } = {},
            }              = mssContent;
      const firstListGiven = s_birthGiven && s_birthGiven.split(' ')[0];
      
      if (s_birthName || s_given) {
        setSelectedPatient({
          
          s_ins                   : patientIns,
          s_patientFirstBirthGiven: s_given || firstListGiven,
          s_patientBirthName      : s_birthName,
          s_patientName           : '',
          s_birthday              : moment.utc(s_birthDate, 'YYYY-MM-DD')
            .format('YYYYMMDD'),
        });
      } else if (accessibleDMPList && accessibleDMPList.AccessibleDmps && accessibleDMPList.AccessibleDmps.length > 0) {
        setSelectedPatient(accessibleDMPList.AccessibleDmps.find(patient => patient.s_ins === patientIns));
      }
    } else {
      setSelectedPatient(undefined);
    }
  }, [patientIns, mssContent]);
  
  React.useEffect(() => {
    if (patientAttachments && patientAttachments.length > 0 && selectedPatient) {
      const typeCode         = categories.find(c => c.code === patientAttachments[0].documentCategory);
      const formattedSubject = formatMssMessageSubject(
        selectedPatient,
        patientAttachments.length,
        typeCode ? typeCode.label : patientAttachments[0].documentCategory,
      );
      
      setSubject(formattedSubject);
      setReadonlySubject(true);
    } else {
      setReadonlySubject(false);
    }
  }, [selectedPatient, patientAttachments]);
  
  React.useEffect(() => {
    if (sendReceiptNotification === true && (!notificationReceivers || notificationReceivers.length === 0)) {
      setNotificationReceivers([{ s_mail: mssEmail, s_name: mssEmail }]);
    }
  }, [sendReceiptNotification]);
  
  const reset = (resetEmailContent) => {
    if (resetEmailContent === true) {
      setPatientIns('');
      setPatientAttachments([]);
      setOtherAttachments([]);
      setRecipientsList([]);
      setCarbonCopyList([]);
      setBlindCarbonCopyList([]);
      setSubject('');
      setMessage('');
      setSendReceiptNotification(false);
      setNotificationReceivers([]);
      setProcessingSteps([]);
      dispatch(resetMssEmailContent());
    }
    dispatch(clearSubSection(apiSections.MSS_GET_HP_INFOS, 'recipients'));
    dispatch(clearSubSection(apiSections.MSS_GET_HP_INFOS, 'cc'));
    dispatch(clearSubSection(apiSections.MSS_GET_HP_INFOS, 'bcc'));
    dispatch(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
    dispatch(clearSection(apiSections.MSS_GENERATE_ATTACHMENTS));
    dispatch(clearSection(apiSections.MSS_IMAP_APPEND_MESSAGE));
  };
  
  React.useEffect(() => {
    reset(false);
  }, []);
  
  const hide = () => {
    if (mssContent.fromRemoteControl === true && !isReady(sendEmailSection)) {
      dispatch(remoteSendMssMessageRefused());
    }
    dispatch(setShowMssPopup(false));
    reset(true);
  };
  
  const checkMssPscTokenValidity = React.useCallback(() => isMssTokenValid(
    externalPscAccessToken,
    mssPscAccessToken,
    dispatch,
  ), [mssPscAccessToken, externalPscAccessToken]);
  
  
  const sendEmail = async () => {
    const tokenIsValid = await checkMssPscTokenValidity();
    
    if (tokenIsValid) {
      const emailContent = {
        sender                         : mssEmail,
        title                          : subject,
        messageContent                 : message,
        recipients                     : recipientsList.map(recipient => recipient.s_mail).join(';'),
        cc                             : carbonCopyList && carbonCopyList.length > 0
                                         ? carbonCopyList.map(cc => cc.s_mail).join(';')
                                         : undefined,
        bcc                            : blindCarbonCopyList && blindCarbonCopyList.length > 0
                                         ? blindCarbonCopyList.map(bcc => bcc.s_mail).join(';')
                                         : undefined,
        attachments                    : patientAttachments || [],
        otherAttachments               : otherAttachments || [],
        isHtml                         : true,
        sendReceiptNotification,
        notificationReceivers          : sendReceiptNotification && notificationReceivers.length === 1
                                         ? notificationReceivers[0].s_mail
                                         : '',
        receiptNotificationType        : mssContent.notificationReceiversType || mssReceiptNotificationType,
        replyTo                        : mssContent.replyTo || mssReplyTo,
        inReplyToMessageIds            : mssContent.inReplyToMessageIds,
        references                     : mssContent.references,
        senderWording                  : mssContent.senderWording || mssSenderWording,
        messageId                      : mssContent.messageId || generateMessageId(),
        Identity                       : mssContent.insiIdentity,
        forceSchematronsValidation,
        ignorePdfA1Transparency        : mssContent.ignorePdfA1Transparency !== undefined
                                         ? mssContent.ignorePdfA1Transparency
                                         : ignorePdfA1Transparency,
        disabledPdfA1Conversion        : mssContent.disabledPdfA1Conversion !== undefined
                                         ? mssContent.disabledPdfA1Conversion
                                         : disabledPdfA1Conversion,
        disableIheXdmPdfTitlePage      : mssContent.disableIheXdmPdfTitlePage,
        disableIheXdmPdfDataMatrixBlock: mssContent.disableIheXdmPdfDataMatrixBlock,
        getDocumentContent             : mssContent.getDocumentContent,
        insIsNotQualified              : mssContent.insIsNotQualified,
        AdditionalPatientIdentifiers   : mssContent.AdditionalPatientIdentifiers,
        endConversation                : mssContent.endConversation,
      };
      dispatch(getSendMssEmailAction(mssApiType, apiType, emailContent, esUser, hpAuthenticationMode));
    }
  };
  
  const addDocumentToAttachment = (document) => {
    const {
            Ins: {
              s_value, s_oid, s_key,
            } = {},
          } = (mssContent.insiIdentity || {});
    
    const category   = categories.find(c => c.code === document.typeCode);
    const attachment = {
      Identity             : mssContent.insiIdentity,
      patientIns           : mssContent.insiIdentity ? `${s_value}${s_key}${insOidToType[s_oid]}` : patientIns,
      fileContentInBase64  : document.base64,
      documentTitle        : document.title,
      documentFilename     : document.filename,
      documentFormat       : document.documentFormat,
      documentDescription  : document.description,
      documentCategory     : document.typeCode,
      documentCategoryLabel: category ? category.label : document.typeCode,
      healthcareSetting    : document.healthcareSetting,
      documentVisibility   : calculateVisibility(
        document.patientHidden,
        document.healthcareProfessionalHidden,
        document.guardianHidden,
        true,
      ),
      creationDate         : document.creationDate,
      serviceStartDate     : document.serviceStartDate,
      serviceStopDate      : document.serviceStopDate,
    };
    setPatientAttachments(attachements => [...attachements, attachment]);
  };
  
  const addToAttachements = (files, attachementType) => {
    files.forEach((file) => {
      const fileFormats = getDocumentFormatByExtension(file.name);
      if (fileFormats.length === 0 && attachementType === attachementTypes.patient) {
        toast(
          <>
            <div>
              Fichier
              {' '}
              {file.name}
              {' '}
              refusé.
            </div>
            <div>
              Format non supporté.
            </div>
          </>,
          {
            type           : 'error',
            position       : 'top-right',
            closeOnClick   : true,
            autoClose      : true,
            hideProgressBar: true,
          },
        );
      } else if (file.size > 5000000) {
        toast(
          <>
            <div>
              Fichier
              {' '}
              {file.name}
              {' '}
              trop volumineux.
            </div>
            <div>
              Taille maximum : 5Mo.
            </div>
          </>,
          {
            type           : 'error',
            position       : 'top-right',
            closeOnClick   : true,
            autoClose      : true,
            hideProgressBar: true,
          },
        );
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const dataUri = event.target.result;
          try {
            const fileContent = dataUri
              .substring(dataUri.lastIndexOf('base64,'))
              .replace('base64,', '');
            
            if (attachementType === attachementTypes.patient) {
              const {
                      Ins: {
                        s_value, s_oid, s_key,
                      } = {},
                    }          = (mssContent.insiIdentity || {});
              const attachment = {
                Identity           : mssContent.insiIdentity,
                patientIns         : mssContent.insiIdentity ? `${s_value}${s_key}${insOidToType[s_oid]}` : patientIns,
                fileContentInBase64: fileContent,
                documentTitle      : file.name,
                documentFormat     : parseInt(fileFormats[0][0], 10),
                documentDescription: '',
                documentCategory   : 'CERT_DECL',
                healthcareSetting,
              };
              setPatientAttachments(attachements => [...attachements, attachment]);
            } else {
              const contentType = mime.lookup(file.name);
              if (mssApiType === mssSubTypes.WEB && contentType.length > 40) {
                toast(
                  <>
                    <div>
                      Fichier
                      {' '}
                      {file.name}
                      {' '}
                      refusé.
                    </div>
                    <div>
                      Format non supporté.
                    </div>
                  </>,
                  {
                    type           : 'error',
                    position       : 'top-right',
                    closeOnClick   : true,
                    autoClose      : true,
                    hideProgressBar: true,
                  },
                );
              } else {
                setOtherAttachments(attachements => [...attachements, {
                  fileContentInBase64: fileContent,
                  documentTitle      : file.name,
                  contentType        : mime.lookup(file.name),
                }]);
              }
            }
          } catch (e) {
            console.log('error pj', e);
          }
        };
      }
    });
  };
  
  return (
    <Popable
      backdrop="static"
      keyboard={false}
      size="lg"
      title={isLoading(sendEmailSection) || isLoading(generateAttachmentsSection) ? 'Envoi de l\'email en cours ...' : 'Envoyer un email'}
      popup
      shown
      handleClose={() => hide()}
      footer={(
        <>
          <Button onClick={() => hide()} variant="outline-secondary">
            {isReady(sendEmailSection) ? 'Fermer' : 'Annuler'}
          </Button>
          {processingSteps.length > 0 && processingSteps.every(step => step.status === stepsStatus.success) && (
            <Button onClick={() => reset(true)} variant="outline-secondary">
              Nouvel Email
            </Button>
          )}
          {mssEmail && processingSteps.length === 0 && (
            <ButtonWithLoader
              onClick={() => sendEmail()}
              loading={isLoading(sendEmailSection) || isLoading(generateAttachmentsSection)}
              loadingLabel="Envoi ..."
              label="Envoyer"
              disabled={(
                (!recipientsList || recipientsList.length === 0)
                || !subject
                || !message
                || !mssEmail
              )}
            />
          )}
        </>
      )}
    >
      <>
        {processingSteps.length > 0 ? (
          <StepsStatusComponent processingSteps={processingSteps}/>
        ) : (
           <>
             {!mssOTPReady && !mssCertReady && !mssCpxReady && !mssPscReady ? (
               <MssConfiguration
                 columnLayout={false}
                 config
                 login
                 title="Veuillez vous connecter à votre compte de Messagerie Sécurisée de Santé."
               />
             ) : (
                <>
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="flex-grow-1 mr-4">
                      <Form.Group as={Row} controlId="mss_from" className="align-items-center">
                        <Form.Label column sm={12} lg={2} className="typography-default-text-title">De</Form.Label>
                        <Col>
                          <Typeahead
                            id="mss_from"
                            disabled
                            defaultSelected={[{ label: mssEmail }]}
                            options={[]}
                            multiple
                            isInvalid={!mssEmail || !isEmailValid(mssEmail)}
                          />
                        </Col>
                      </Form.Group>
                      
                      <Form.Group as={Row} className="align-items-start" controlId="mss_recipients">
                        <Form.Label column sm={12} lg={2} className="typography-default-text-title">
                          Destinataires
                          <TitleTooltip
                            id="mss_recipients_info"
                            text={(
                              <>
                                Permet la recherche par nom et prénom :
                                {' '}
                                <strong>[nom]espace[prénom]</strong>
                                .
                                <br/>
                                Le caractère
                                {' '}
                                <strong>*</strong>
                                {' '}
                                permet une recherche approximative.
                              </>
                            )}
                          >
                            <i className="ic-info"/>
                          </TitleTooltip>
                        </Form.Label>
                        <Col>
                          <AddressEmailAsyncTypeAhead
                            id="mss_recipients"
                            selectedAddresses={recipientsList}
                            onChange={selection => setRecipientsList(selection)}
                            subSection="recipients"
                            isInvalid={!recipientsList || recipientsList.length === 0 || recipientsList.some(email => !isEmailValid(email.s_mail))}
                          />
                          <div className="small text-right">
                            {!showCC && (
                              <TitleTooltip
                                id="cc_tooltip"
                                text="Ajouter un destinataire en Cc."
                              >
                                <Button variant="link" size="xs" onClick={() => setShowCC(!showCC)}>Cc</Button>
                              </TitleTooltip>
                            )}
                            {!showBCC && (
                              <TitleTooltip
                                id="cci_tooltip"
                                text="Ajouter un destinataire en Cci."
                              >
                                <Button
                                  variant="link"
                                  size="xs"
                                  className="ml-2"
                                  onClick={() => setShowBCC(!showBCC)}
                                >
                                  Cci
                                </Button>
                              </TitleTooltip>
                            )}
                          </div>
                        </Col>
                      </Form.Group>
                      
                      <Form.Group as={Row} style={{ display: showCC ? 'flex' : 'none' }} controlId="mss_cc" className="align-items-center">
                        <Form.Label column sm={12} lg={2} className="typography-default-text-title">Cc</Form.Label>
                        <Col>
                          <AddressEmailAsyncTypeAhead
                            id="mss_cc"
                            selectedAddresses={carbonCopyList}
                            onChange={selection => setCarbonCopyList(selection)}
                            subSection="cc"
                          />
                        </Col>
                      </Form.Group>
                      
                      <Form.Group as={Row} style={{ display: showBCC ? 'flex' : 'none' }} controlId="mss_bcc"
                                  className="align-items-center">
                        <Form.Label column sm={12} lg={2} className="typography-default-text-title">Bcc</Form.Label>
                        <Col>
                          <AddressEmailAsyncTypeAhead
                            id="mss_bcc"
                            selectedAddresses={blindCarbonCopyList}
                            onChange={selection => setBlindCarbonCopyList(selection)}
                            subSection="bcc"
                          />
                        </Col>
                      </Form.Group>
                      
                      <Form.Group as={Row} controlId="mss_subject" className="align-items-center">
                        <Form.Label column sm={12} lg={2} className="typography-default-text-title">Objet</Form.Label>
                        <Col>
                          {readonlySubject ? (
                            <>{subject}</>
                          ) : (
                             <Form.Control
                               value={subject}
                               onChange={event => setSubject(event.target.value)}
                               isInvalid={!subject}
                             />
                           )}
                        </Col>
                      </Form.Group>
                      
                      {(!mssClientMode || (mssClientMode && (mssContent.forcedIns || selectedPatient))) && (
                        <>
                          {mssContent.forcedIns !== true ? (
                            <Form.Group as={Row} controlId="mss_subject" className="align-items-center">
                              <Form.Label column sm={12} lg={2} className="typography-default-text-title">Patient</Form.Label>
                              <Col>
                                <CustomTypeahead
                                  className="custom-typeahead"
                                  name="patientIns"
                                  id="patientIns"
                                  options={
                                    accessibleDMPList && accessibleDMPList.AccessibleDmps
                                    ? accessibleDMPList.AccessibleDmps.filter(dmp => !!dmp.s_ins)
                                      .sort(patientSort)
                                    : []
                                  }
                                  selectionRule={item => item.s_ins === patientIns}
                                  labelKey={patient => `${getFullName(patient.s_patientName, patient.s_patientFirstBirthGiven, patient.s_patientBirthName)} (${patient.s_ins})`}
                                  valueKey="s_ins"
                                  placeholder=""
                                  value={patientIns}
                                  onChange={event => setPatientIns(event.target.value)}
                                  renderMenu={renderPatientAutoComplete}
                                />
                              </Col>
                            </Form.Group>
                          ) : (
                             <>
                               {selectedPatient ? (
                                 <Form.Group as={Row} controlId="mss_subject" className="align-items-center">
                                   <Form.Label column sm={12} lg={2} className="typography-default-text-title">Patient</Form.Label>
                                   <Col>
                                     {`${getFullName(selectedPatient.s_patientName, selectedPatient.s_patientFirstBirthGiven, selectedPatient.s_patientBirthName)} (${selectedPatient.s_ins})`}
                                   </Col>
                                 </Form.Group>
                               ) : (
                                  <Form.Group as={Row} controlId="mss_subject" className="align-items-center">
                                    <Form.Label column sm={12} lg={2} className="typography-default-text-title">Patient</Form.Label>
                                    <Col>
                                      {patientIns}
                                    </Col>
                                  </Form.Group>
                                )}
                             </>
                           
                           )}
                          
                          <Form.Group as={Row} controlId="mss_patient_attachments" className="align-items-center">
                            <Form.Label column sm={12} lg={2} className="typography-default-text-title">PJ XDM</Form.Label>
                            <Col>
                              <MssDocumentSelector
                                disabled={!patientIns}
                                ins={patientIns}
                                patientInfos={selectedPatient}
                                id="mss_patient_attachments"
                                files={patientAttachments}
                                onDrop={document => addDocumentToAttachment(document)}
                                onDelete={(file, index) => setPatientAttachments(attachements => attachements.filter((att, i) => i !== index))}
                                fileNameTransfo={fileName => `${fileName}`}
                              />
                              {!patientIns && (
                                <Form.Text className="text-muted">
                                  Veuillez choisir un patient avant de pouvoir lui associer des PJ
                                </Form.Text>
                              )}
                            </Col>
                          </Form.Group>
                        </>
                      )}
                      
                      <Form.Group as={Row} controlId="mss_other_attachments" className="align-items-center">
                        <Form.Label column sm={12} lg={2} className="typography-default-text-title">
                          {(!mssClientMode || (mssClientMode && mssContent.fromRemoteControl && (mssContent.forcedIns || selectedPatient)))
                           ? 'Autres PJ'
                           : 'PJ'
                          }
                        </Form.Label>
                        <Col>
                          <InputFileWithTokens
                            id="mss_other_attachments"
                            files={otherAttachments}
                            onDrop={files => addToAttachements(files, attachementTypes.other)}
                            onDelete={(file, index) => setOtherAttachments(attachements => attachements.filter((att, i) => i !== index))}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <i className="ic-mss flex-grow-0"/>
                  </div>
                  
                  <Form.Group controlId="mss_message" className={`${!message ? 'invalid' : ''} mss-editor`}>
                    <Form.Label className="typography-default-text-title">Message</Form.Label>
                    {Platform.Browser === 'IE' ? (
                      <Form.Control
                        as="textarea"
                        onChange={(event) => {
                          setMessage(event.target.value);
                        }}
                        isInvalid={!message}
                      />
                    ) : (
                       <CKEditor
                         config={{ language: 'fr' }}
                         editor={ClassicEditor}
                         data={message}
                         onChange={(event, editor) => {
                           const data = editor.getData();
                           setMessage(data);
                         }}
                         isInvalid={!message}
                       />
                     )}
                  </Form.Group>
                  {(apiType === API_TYPES.REST || (connectorVersion && checkDMPConnectJSVersion(connectorVersion, '0.9.8'))) && (
                    <>
                      <Form.Group as={Row} controlId="mss_sendNotification" className="align-items-center justify-content-between">
                        <Col sm="auto">
                          <Form.Check
                            custom
                            onChange={e => setSendReceiptNotification(e.target.checked)}
                            checked={sendReceiptNotification}
                            type="checkbox"
                            label="Demander un accusé de réception"
                          />
                        </Col>
                        {sendReceiptNotification && (
                          <Col sm="auto">
                            <button
                              type="button"
                              className="btn btn-link btn-icon-only p-0"
                              onClick={() => setShowNotificationReceivers(!showNotificationReceivers)}
                            >
                              <i className={showNotificationReceivers ? 'ic-chevron-up' : 'ic-chevron-down'} style={{ fontSize: '10px' }}/>
                            </button>
                          </Col>
                        )}
                      </Form.Group>
                      {sendReceiptNotification && showNotificationReceivers && (
                        <Form.Group controlId="mss_sendNotification" className="align-items-center">
                          <Form.Label className="typography-default-text-title">
                            Adresses de réception de l&apos;accus&eacute; de r&eacute;ception
                          </Form.Label>
                          <AddressEmailAsyncTypeAhead
                            id="mss_notificationReceivers"
                            selectedAddresses={notificationReceivers}
                            onChange={selection => setNotificationReceivers(selection)}
                            subSection="notificationReceivers"
                            multiple={false}
                          />
                        </Form.Group>
                      )}
                      
                      {externalPscAccessToken && !mssPscAccessToken && mssLoginType === mssLoginTypes.PSC && (
                        <Alert type="danger">
                          En attente d&apos;un token d&apos;accès valide.
                        </Alert>
                      )}
                    </>
                  )}
                </>
              )}
           </>
         )}
      </>
    </Popable>
  );
};

function mapStateToProps(state) {
  const
    {
      dmpconnect: {
        selectedIns                             : ins,
        [apiSections.MSS_PERFORM_AUTHENTICATION]: performMssAuth,
        [apiSections.MSS_SEND_SMTP_EMAIL]       : sendEmailSection,
        [apiSections.MSS_IMAP_APPEND_MESSAGE]   : appendMessageSection,
        [apiSections.MSS_GENERATE_ATTACHMENTS]  : generateAttachmentsSection,
        [apiSections.MSS_FINALIZE_OTP_AUTH]     : finalizeOtpAuth,
        [apiSections.SESSION_SECTION]           : {
          s_serviceVersion: connectorVersion,
        },
      },
      
      dmpconnectApplication: {
        mssContent,
      },
      
      dmpConnectPersistedAppConfiguration: {
        mssAuthenticationId: authenticationId,
        forceSchematronsValidation = false,
        ignorePdfA1Transparency    = false,
        disabledPdfA1Conversion    = false,
        mssClientMode,
        externalPscAccessToken,
      },
      
      dmpconnectMSSConfiguration: {
        mssReceiptNotificationType,
        mssPscAccessToken,
      },
      
      dmpconnectUser: { esUser },
      
      dmpconnectAccessibleDmpList: accessibleDMPListCache,
      dmpconnectConnectorConfig,
      dmpconnectESConfiguration,
      dmpconnectInteropCodes: {
        [apiSections.INTEROPCODES]: {
          categories,
        },
      },
    } = state;
  
  
  let selectedIns = ins;
  if (!ins) {
    const {
            insiIdentity: {
              Ins: {
                s_value, s_oid, s_key,
              } = {},
            } = {},
            attachments,
          } = mssContent;
    
    if (s_value && s_key && s_oid) {
      selectedIns = `${s_value}${s_key}${insOidToType[s_oid]}`;
    } else if (attachments && attachments.length > 0) {
      selectedIns = attachments[0].patientIns;
    }
  }
  
  const air              = isAirActive(state);
  const airOnly          = getConfigurationValue('airOnly', dmpconnectConnectorConfig);
  const { accessRights } = getAccessRightsProps(state);
  
  const { mssEmailConfig } = mssContent;
  let mssAccountConfig     = {};
  if (mssEmailConfig) {
    mssAccountConfig = getMssAccountConfigByEmail(state, mssEmailConfig, true);
  } else {
    mssAccountConfig = getActiveMssAccountConfig(state);
  }
  
  const
    {
      mssEmail,
      mssApiType,
      mssSenderWording,
      mssReplyTo,
      mssLoginType
    } = mssAccountConfig;
  
  return {
    hpAuthenticationMode: getConfigurationValue('hpAuthenticationMode', dmpconnectESConfiguration) || 8,
    accessibleDMPList   : getAccessibleDMPListCache(
      accessibleDMPListCache,
      accessRights.psId,
      air && !airOnly ? accessRights.esId : null,
      isTransactionAllowed(accessRights, transactions.AUTHORIZED_DMPS),
    ),
    sendEmailSection,
    appendMessageSection,
    generateAttachmentsSection,
    mssEmail,
    mssContent,
    mssReceiptNotificationType,
    apiType             : getApiType(state),
    finalizeOtpAuth,
    authenticationId,
    esUser,
    connectorVersion,
    ins                 : selectedIns || '',
    healthcareSetting   : getHealthcareSetting(state),
    mssApiType,
    forceSchematronsValidation,
    ignorePdfA1Transparency,
    disabledPdfA1Conversion,
    mssSenderWording,
    mssReplyTo,
    mssLoginType,
    performMssAuth,
    mssCpxReady         : isMssCpxReady(state),
    mssOTPReady         : isMssOTPReady(state),
    mssCertReady        : isMssCertReady(state),
    mssPscReady         : isMssPSCReady(state),
    externalPscAccessToken,
    mssPscAccessToken,
    mssClientMode,
    categories,
  };
}

MssSendEmail.propTypes = {
  dispatch                  : PropTypes.func.isRequired,
  sendEmailSection          : PropTypes.object,
  appendMessageSection      : PropTypes.object,
  generateAttachmentsSection: PropTypes.object,
  finalizeOtpAuth           : PropTypes.object,
  mssContent                : PropTypes.object,
  esUser                    : PropTypes.object,
  mssEmail                  : PropTypes.string.isRequired,
  apiType                   : PropTypes.string.isRequired,
  connectorVersion          : PropTypes.string,
  mssReceiptNotificationType: PropTypes.string.isRequired,
  ins                       : PropTypes.string,
  accessibleDMPList         : PropTypes.object,
  healthcareSetting         : PropTypes.string.isRequired,
  mssApiType                : PropTypes.string.isRequired,
  hpAuthenticationMode      : PropTypes.number,
  forceSchematronsValidation: PropTypes.bool,
  ignorePdfA1Transparency   : PropTypes.bool,
  disabledPdfA1Conversion   : PropTypes.bool,
  mssSenderWording          : PropTypes.string,
  mssReplyTo                : PropTypes.string,
  mssLoginType              : PropTypes.string.isRequired,
  performMssAuth            : PropTypes.object,
  
  mssCpxReady           : PropTypes.bool,
  mssOTPReady           : PropTypes.bool,
  mssCertReady          : PropTypes.bool,
  mssPscReady           : PropTypes.bool,
  mssClientMode         : PropTypes.bool,
  externalPscAccessToken: PropTypes.bool,
  mssPscAccessToken     : PropTypes.string,
  categories            : PropTypes.array.isRequired,
};

MssSendEmail.defaultProps = {
  accessibleDMPList         : {},
  sendEmailSection          : {},
  appendMessageSection      : {},
  generateAttachmentsSection: {},
  finalizeOtpAuth           : {},
  mssContent                : {},
  esUser                    : {},
  connectorVersion          : null,
  ins                       : '',
  hpAuthenticationMode      : 8,
  
  forceSchematronsValidation: false,
  ignorePdfA1Transparency   : false,
  disabledPdfA1Conversion   : false,
  mssSenderWording          : '',
  mssReplyTo                : '',
  performMssAuth            : {},
  mssCpxReady               : false,
  mssOTPReady               : false,
  mssCertReady              : false,
  mssPscReady               : false,
  mssClientMode             : false,
  externalPscAccessToken    : false,
  mssPscAccessToken         : undefined,
};

const ConnectedMssSendEmail = connect(mapStateToProps)(MssSendEmail);

export default ConnectedMssSendEmail;
